import React, {FC, ReactElement} from 'react';
import {Alert, AlertTitle, Box, Button, Card, CardContent, CircularProgress, Divider, Typography} from "@mui/material";
import Header from "./Header";
import InvoiceSection from "./InvoiceSection";
import SupplierBuyer from "./SupplierBuyer";
import InvoiceItems from "./InvoiceItems";
import Footer from "./Footer";
import {getLabelValue} from "../utils";
import {IError, ISuccess} from "../types";

interface IProps {
    invoice: any;
    isMobile: boolean;
    lang: string;
    goBack: () => void;
    isTransaction?: boolean;
    claimInvoice?: () => void;
    loading?: boolean;
    error?: IError | undefined;
    onErrorClose?: () => void;
    success?: ISuccess | undefined;
    onSuccessClose?: () => void;
}

const Invoice: FC<IProps> = (props:IProps) => {
    const {
        invoice,
        isMobile, lang, goBack,
        isTransaction=false,
        claimInvoice,
        loading = false,
        error,
        onErrorClose,
        success,
        onSuccessClose
    } = props;

    const renderRelatedDocuments = (): ReactElement | null => {
        if (isTransaction) return null;
        if (!invoice?.hasOwnProperty("related_documents")) return null;
        if (!Array.isArray(invoice.related_documents)) return null;

        return invoice.related_documents.map((i: any, v: number) => {
            return (
                <Box>
                    <Divider sx={{ paddingTop: 1}} />
                    <InvoiceSection
                        details={{
                            invoice_number: i?.invoice_number || '',
                            document_date: i?.document_date || '',
                            document_type: i?.document_type || '',
                            submission_status: i?.submission_status || 'Pending'
                        }}
                        isMobile={isMobile}
                        isTransaction={isTransaction}
                    />
                    <Divider sx={{ paddingTop: 2}} />
                    <InvoiceItems
                        items={i?.items}
                        currency={i?.currency}
                        total_payable={i?.total_payable}
                        tax_total={i?.tax_total}
                        total_amount={i?.total_amount}
                    />
                    <Footer qrcode={i?.qr_code} submission_status={i?.submission_status } />
                </Box>
            )
        })
    }

    return (
        <Box
            sx={{
                backgroundColor: isMobile ? 'white' : 'lightgray',
                width: '100vw',
                //height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            my={isMobile ? 8 : 10}
        >
            <Card
                sx={{
                    width: isMobile ? '100%' :'80%',
                    padding: isMobile ? '10px' : '20px',
                    borderRadius: 0
                }}
            >
                <CardContent>
                    <Header details={invoice?.supplier_data} />
                    {!isTransaction && (
                        <Divider sx={{ paddingTop: 1}} />
                    )}
                    <InvoiceSection
                        details={{
                            invoice_number: invoice?.invoice_number || '',
                            document_date:
                                isTransaction ? (invoice?.invoice_date || '') : (invoice?.document_date || ''),
                            document_type: invoice?.document_type || '',
                            submission_status: invoice?.submission_status || 'Pending'
                        }}
                        isMobile={isMobile}
                        isTransaction={isTransaction}
                    />
                    <Divider sx={{ paddingTop: 2}} />
                    <SupplierBuyer
                        supplier={invoice?.supplier_data}
                        buyer={invoice?.buyer_data}
                        isMobile={isMobile}
                        document_type={invoice?.document_type || ''}
                    />
                    {!isTransaction && (
                        <Divider sx={{ paddingTop: 1}} />
                    )}
                    {isTransaction && (
                        <Box display='flex' sx={{ justifyContent: 'center', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    m: 2,
                                    p: 1,
                                    border: 'dashed',
                                    borderWidth: 2,
                                    width: '50%',
                                    align: 'center',
                                }}
                            >
                                <Alert severity="info">
                                    <AlertTitle>{getLabelValue(lang, 'claim_disclaimer')}</AlertTitle>
                                    {getLabelValue(lang, 'claim_disclaimer_desc')}
                                </Alert>
                            </Box>
                        </Box>
                    )}
                    <InvoiceItems
                        items={invoice?.items}
                        currency={invoice?.currency}
                        total_payable={invoice?.total_payable}
                        tax_total={invoice?.tax_total}
                        total_amount={invoice?.total_amount}
                    />
                    {!isTransaction && (
                        <Footer qrcode={invoice?.qr_code} submission_status={invoice?.submission_status } />
                    )}
                    {renderRelatedDocuments()}
                    {(error && error.error) && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            mt={2}
                        >
                            <Alert
                                variant="filled"
                                severity="error"
                                onClose={onErrorClose}
                            >
                                {error.message}
                            </Alert>
                        </Box>
                    )}
                    {(success && success.success) && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            mt={2}
                        >
                            <Alert
                                variant="filled"
                                severity="success"
                                onClose={onSuccessClose}
                            >
                                {success.message}
                            </Alert>
                        </Box>
                    )}
                    {success && success.success ? (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-evenly"
                        >
                            <Button
                                variant="contained"
                                color="info"
                                sx={{ marginTop: '20px', paddingX: 5, paddingY: 1 }}
                                onClick={onSuccessClose}
                            >
                                {getLabelValue(lang, 'claim_check_invoice')}
                            </Button>
                        </Box>
                    ) : (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-evenly"
                        >
                            <Button
                                variant="contained"
                                color="error"
                                sx={{ marginTop: '20px', paddingX: 5, paddingY: 1 }}
                                onClick={() => goBack()}
                            >
                                {getLabelValue(lang, 'home_back_button')}
                            </Button>
                            {isTransaction && (
                                <Button
                                    variant="contained"
                                    color="success"
                                    disabled={loading}
                                    endIcon={loading ? <CircularProgress size={10} /> : null}
                                    sx={{ marginTop: '20px', paddingX: 5, paddingY: 1 }}
                                    onClick={() => claimInvoice ? claimInvoice() : null}
                                >
                                    {getLabelValue(lang,'claim_button')}
                                </Button>
                            )}
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Box>
    )
}

export default Invoice;
