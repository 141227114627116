import React, {FC} from "react";
import {Box, Grid, Typography} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

interface IProps {
    supplier: any;
    buyer: any;
    isMobile: boolean;
    document_type: string
}

const SupplierBuyer: FC<IProps> = (props: IProps) => {
    const {supplier, buyer, isMobile, document_type} = props;
    if (supplier === undefined && buyer === undefined)
        return null;

    const {
        supplier_tin, sst_reg_no, brn, msic_code,
        businessname, supplier_address_line1, supplier_phone,
        supplier_msic_description
    } = supplier;
    const {
        buyer_name, buyer_tin, buyer_address_line1,
        buyer_phone, buyer_sst_no, buyer_msic_code,
        buyer_msic_description, buyer_idvalue
    } = buyer;

    const isSelfBilled: boolean = document_type >= '10';

    return (
        <Box py={5}>
            <Grid container justifyContent="space-between">
                <Grid2 xs={12} md={6}>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Supplier TIN:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2">
                                {isSelfBilled ? (buyer_tin || '') : (supplier_tin || '')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Supplier Name:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2">
                                {isSelfBilled ? (buyer_name || '') : (businessname || '')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Supplier Registration Number:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2">
                                {isSelfBilled ? (buyer_idvalue || '') : (brn || '')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Supplier SST ID:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2">
                                {isSelfBilled ? (buyer_sst_no ||  'N/A') : (sst_reg_no || '')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Supplier MSIC code:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2">
                                {isSelfBilled ? (buyer_msic_code || '') :  (msic_code || '')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Supplier MSIC code:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2">
                                {isSelfBilled ? (buyer_msic_description || '') :  (supplier_msic_description || '')}
                            </Typography>
                        </Box>
                    </Box>
                </Grid2>
                <Grid2 xs={12} md={6} pt={isMobile ? 2 : 0}>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Buyer TIN:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2" color={"blue"} fontWeight={"bold"}>
                                {isSelfBilled ? (supplier_tin || '') : (buyer_tin || '')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Buyer Name:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2" fontWeight={"bold"} color={"blue"}>
                                {isSelfBilled ? (businessname || '') : (buyer_name || '')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Buyer Registration No:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2">
                                {isSelfBilled ? (supplier?.brn || 'N/A') : (buyer?.brn || 'N/A')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Buyer Address:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2">
                                {isSelfBilled ? (supplier_address_line1 || 'N/A') : (buyer?.buyer_address_line1 || 'N/A')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Buyer Contact No:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2">
                                {isSelfBilled ? (supplier_phone || 'N/A') :  (buyer_phone || 'N/A')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography variant="body2">
                                Buyer SST Registration No:
                            </Typography>
                        </Box>
                        <Box pl={1}>
                            <Typography variant="body2">
                                {buyer_sst_no || 'N/A'}
                            </Typography>
                        </Box>
                    </Box>
                </Grid2>
            </Grid>
        </Box>
    )
}

export default SupplierBuyer;
